import type { CustomElement } from '@integrabeauty/custom-elements';
import html from './index.html';
import styles from './index.scss';

/**
 * Renders Yotpo site rating badge.
 */
class YotpoBadge extends HTMLElement implements CustomElement {
  public static get observedAttributes() {
    return [
      'data-total',
      'data-average-score'
    ];
  }

  readonly dataset!: {
    /**
     * Overall rating
     */
    averageScore: string;

    /**
     * URL to badge image in the assets folder
     */
    badgeImageSrc: string;

    /**
     * Total number of votes
     */
    total: string;

    /**
     * URL where the badge click will lead to
     */
    url: string;
  };

  constructor() {
    super();
    this.attachShadow({ mode: 'open' });
    this.shadowRoot.innerHTML = `<style>${styles}</style>${html}`;
  }

  public connectedCallback() {
    this.render();
  }

  public attributeChangedCallback(_name: string, oldValue: string, newValue: string) {
    if (this.isConnected && oldValue !== newValue) {
      this.render();
    }
  }

  private render() {
    const link = this.shadowRoot.querySelector<HTMLAnchorElement>('a');
    link.href = this.dataset.url || '#';

    const imageElement = this.shadowRoot.querySelector('img');
    imageElement.src = this.dataset.badgeImageSrc;

    const totalElement = this.shadowRoot.querySelector('.total');
    const total = parseInt(this.dataset.total, 10) / 1000000;

    const totalText = Number.isNaN(total) ? '' : total.toFixed(1);

    totalElement.textContent = totalText ? totalText + 'M' : '';

    const score = this.dataset.averageScore ? this.dataset.averageScore : '';

    const badge = this.shadowRoot.querySelector<HTMLElement>('.badge');
    if (score && totalText) {
      badge?.setAttribute('aria-label', [
        score,
        'average score based on',
        totalText,
        'million certified reviews',
        'powered by Yotpo and Trustpilot.'
      ].join(' '));
    } else {
      badge?.setAttribute('aria-label', 'Certified reviews, powered by Yotpo and Trustpilot.');
    }
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'yotpo-badge': YotpoBadge;
  }
}

if (!customElements.get('yotpo-badge')) {
  customElements.define('yotpo-badge', YotpoBadge);
}
